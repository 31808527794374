import React, { useEffect } from 'react';
import firebase from '../../Firebase';

export default function useRipple(organisationId, streamId, rippleId) {
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [ripple, setRipple] = React.useState(null)

  useEffect(
    () => {
      // Reset state
      setLoading(true);
      setRipple(null);
      setError(null);

      let unsubscribe = null;

      const fetchData = async () => {
        unsubscribe = firebase.firestore()
          .collection('organisations')
          .doc(organisationId)
          .collection('streams')
          .doc(streamId)
          .collection('ripples')
          .doc(rippleId)
          .onSnapshot(rippleDoc => {
            setRipple(rippleDoc.exists ? { id: rippleDoc.id, ...rippleDoc.data(), createTime: rippleDoc.get('createTime')?.toDate() } : null);
            setLoading(false);
            setError(false);
          }, err => { 
            // An error occurred while trying to load the snapshot
            setRipple(false);
            setError(err) 
            setLoading(false); 
          });
      }

      if (organisationId && streamId) {
        fetchData();
      } else {
        setRipple(null);
        setError(null) 
        setLoading(false); 
      }

      // If we're currently subscribed to watch for changes we need to clean that up when the component unmounts
      return () => { if (unsubscribe) { unsubscribe(); } }
    },
    [organisationId, streamId, rippleId]
  )

  return {
    error,
    loading,
    ripple,
  }
}