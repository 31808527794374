import firebase from '../../Firebase';

export async function VerifyOrganisation(organisationId) {
  var func = firebase.functions().httpsCallable('admin_verifyOrganisation');
  return await func({organisationId});
}

export async function DeleteOrganisation(organisationId) {
  var func = firebase.functions().httpsCallable('admin_deleteOrganisation');
  return await func({organisationId});
}