import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useOrganisation from 'hooks/firestore/useOrganisation';
import useStreams from 'hooks/firestore/useStreams';
import { List, Avatar, Tag, Typography, Breadcrumb, Skeleton, Button } from 'antd';
import VerifyButton from 'components/VerifyButton';
import Page from 'components/layout/Page';
import { GetImage, ImageSize } from 'services/images';
import environment from 'environment';
import DeleteOrganisationButton from 'components/DeleteOrganisationButton';

const { Title, Paragraph } = Typography;

export default function Organisation({ location }) {
  let { organisationId } = useParams();
  const { organisation } = useOrganisation(organisationId);
  const { loading: streamsLoading, streams } = useStreams(organisationId);
  
  const breadcrumb = <Breadcrumb>
    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
  </Breadcrumb>

  return <Page breadcrumb={breadcrumb}>
    <Header organisation={organisation} />
    <Streams organisationId={organisationId} streams={streams} loading={streamsLoading} />
  </Page>
};

function Header({organisation}) {
  if (organisation) {
    let verify = organisation && (organisation.verified ? <Tag color="success">Verified</Tag> : <VerifyButton organisationId={organisation.id} />);
    let impersonate = <a href={`${environment.appUrl}/admin/impersonate/${organisation.ownerId}`} target="_blank" rel="noopener noreferrer"><Button type="link">Impersonate</Button></a>
    const deleteOrg = <DeleteOrganisationButton organisationId={organisation.id} organisationName={organisation.name} />
    return <React.Fragment>
      <Title level={2} style={{marginBottom: 0}}>{organisation?.name}<span style={{float:'right'}}>{verify} {impersonate} {deleteOrg} </span></Title>
      <Paragraph>{`Joined ${organisation?.createTime?.toLocaleDateString("en-NZ")}`}</Paragraph>
    </React.Fragment>
  }

  return <React.Fragment>
    <Skeleton active title={true} paragraph={false} />
    <Skeleton active title={false} paragraph={{rows: 1, width: 150}} />
  </React.Fragment>
}

function Streams({organisationId, streams, loading}) {
  return <React.Fragment>
    <Title level={3}>Streams</Title>
    <List dataSource={streams || []} loading={loading} renderItem={stream => {
      const description = <React.Fragment>
        <Paragraph style={{fontSize: 12}}>{`Created ${stream.createTime?.toLocaleDateString("en-NZ")}`}</Paragraph>
        <Paragraph ellipsis={{rows: 3, expandable: true}}>{stream.description}</Paragraph>
      </React.Fragment>

      return <List.Item key={stream.id}>
        <Link to={`/organisations/${organisationId}/streams/${stream.id}`} style={{width: '100%'}}>
          <List.Item.Meta avatar={<Avatar size={100}  src={GetImage(stream.coverImage, ImageSize.small)} />} title={stream.name} description={description}></List.Item.Meta>
        </Link>
      </List.Item>
    }} />
  </React.Fragment>
}