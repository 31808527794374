import React, { useEffect } from 'react';
import firebase from '../../Firebase';

export default function useOrganisations() {
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [organisations, setOrganisations] = React.useState(null)

  useEffect(
    () => {
      // Reset state
      setLoading(true);
      setOrganisations(null);
      setError(null);

      let unsubscribe = null;

      const fetchData = async () => {
        unsubscribe = firebase.firestore()
          .collection('organisations')
          .onSnapshot(snapshot => {
            setOrganisations(snapshot.empty ? null : snapshot.docs.map(org => { return { id: org.id, ...org.data(), createTime: org.get('createTime')?.toDate() }}));
            setLoading(false);
            setError(false);
          }, err => { 
            // An error occurred while trying to load the snapshot
            setOrganisations(null);
            setError(err) 
            setLoading(false); 
          });
      }

      fetchData();

      // If we're currently subscribed to watch for changes we need to clean that up when the component unmounts
      return () => { if (unsubscribe) { unsubscribe(); } }
    },
    []
  )

  return {
    error,
    loading,
    organisations,
  }
}