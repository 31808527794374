import React, { useEffect } from 'react';
import firebase from '../../Firebase';

export default function useOrganisation(organisationId) {
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [organisation, setOrganisation] = React.useState(null)

  useEffect(
    () => {
      // Reset state
      setLoading(true);
      setOrganisation(null);
      setError(null);

      let unsubscribe = null;

      const fetchData = async () => {
        unsubscribe = firebase.firestore()
          .collection('organisations')
          .doc(organisationId)
          .onSnapshot(orgDoc => {
            setOrganisation(orgDoc.exists ? { id: orgDoc.id, ...orgDoc.data(), createTime: orgDoc.get('createTime')?.toDate() } : null);
            setLoading(false);
            setError(false);
          }, err => { 
            // An error occurred while trying to load the snapshot
            setOrganisation(false);
            setError(err) 
            setLoading(false); 
          });
      }

      if (organisationId) {
        fetchData();
      } else {
        setOrganisation(null);
        setError(null) 
        setLoading(false); 
      }

      // If we're currently subscribed to watch for changes we need to clean that up when the component unmounts
      return () => { if (unsubscribe) { unsubscribe(); } }
    },
    [organisationId]
  )

  return {
    error,
    loading,
    organisation,
  }
}