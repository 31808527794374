import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import useOrganisation from 'hooks/firestore/useOrganisation';
import useStream from 'hooks/firestore/useStream';
import { Typography, Breadcrumb, Row, Col, Spin, Table, Divider, Statistic, List, Card, Carousel, Tag } from 'antd';
import Page from 'components/layout/Page';
import { GetReportData } from 'services/functions/reports';

const { Title, Paragraph } = Typography;

export default function StreamReport({ location }) {
  let { organisationId, streamId } = useParams();
  const { organisation } = useOrganisation(organisationId);
  const { stream } = useStream(organisationId, streamId);

  const breadcrumb = <Breadcrumb>
    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
    <Breadcrumb.Item><Link to={`/organisations/${organisation?.id}`}>{organisation?.name}</Link></Breadcrumb.Item>
    <Breadcrumb.Item><Link to={`/organisations/${organisation?.id}/streams/${stream?.id}`}>{stream?.name}</Link></Breadcrumb.Item>
  </Breadcrumb>

  return <Page breadcrumb={breadcrumb}>
    <Title level={2} style={{marginBottom: 0}}>Report Data</Title>
    <Title level={3}>{stream?.name}</Title>
    <Divider />
    <ReportData organisationId={organisation?.id} stream={stream} />
  </Page>
};

function ReportData({organisationId, stream}) {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      let result = await GetReportData(organisationId, stream.id);
      setData(result.data);
    }

    if (organisationId && stream?.id) {
      fetchData();
    }
  }, [organisationId, stream?.id])


  if (!data) {
    return <Spin style={{textAlign: 'center', display: 'block', marginTop: '5rem'}} />
  }

  const totalViews = data.ripples.reduce((a, b) => a + b['totalViews'], 0);
  const totalCompletions = data.ripples.reduce((a, b) => a + b['totalCompletions'], 0);
  const totalCheckins = data.ripples.reduce((a, b) => a + b['checkins'], 0);

  return <React.Fragment>
    <Row>
      <Col flex="120px"><Statistic title="Ripples" value={data.ripples.length} /></Col>
      <Col flex="120px"><Statistic title="Members" value={data.memberships.length} /></Col>
      <Col flex="120px"><Statistic title="Views" value={totalViews} /></Col>
      <Col flex="120px"><Statistic title="Completions" value={totalCompletions} /></Col>
      <Col flex="120px"><Statistic title="Checkins" value={totalCheckins} /></Col>
    </Row>
    <Divider />
    <Title level={4}>Ripples</Title>
    <RipplesTable ripples={data.ripples} />
    <Divider />
    <Title level={4}>Members</Title>
    <MembersTable stream={stream} memberships={data.memberships} />
    <Divider />
    <Title level={4}>Member Stories</Title>
    <Checkins checkins={data.checkins} />
  </React.Fragment>

}

function RipplesTable({ripples}) {
  const ripplesParsed = ripples.map(ripple => {
    let release = (ripple.releaseDate ? new Date(ripple.releaseDate) : ripple.releaseDays) || 0;
    return { 
      key: ripple.id, 
      title: ripple.title, 
      type: ripple.type, 
      release, 
      releaseNumeric: release instanceof Date ? release.getTime() : release,
      views: ripple.totalViews, 
      viewers: ripple.totalDistinctViewers, 
      completions: ripple.totalCompletions, 
      checkins: ripple.checkins 
    }
  })


  const columns = [
    { title: 'Title', dataIndex: 'title', key: 'title', sorter: (a, b) => a.title.localeCompare(b.title) },
    { title: 'Type', dataIndex: 'type', key: 'type', filters:[{ text: 'action', value: 'action'}, { text: 'event', value: 'event'}, { text: 'resource', value: 'resource'}], onFilter: (value, record) => record.type.indexOf(value) === 0, sorter: (a, b) => a.type.localeCompare(b.type) },
    { title: 'Release Date', dataIndex: 'release', key: 'release', render: (text, record) => { if (!record.release) { return 'Immediately'} return typeof record.release == 'number' ? `${record.release} days` : record.release.toLocaleDateString("en-NZ") }, sorter: (a, b) => a.releaseNumeric - b.releaseNumeric },
    { title: 'Views', dataIndex: 'views', key: 'views', sorter: (a, b) => a.views - b.views },
    { title: 'Viewers', dataIndex: 'viewers', key: 'viewers', sorter: (a, b) => a.viewers - b.viewers},
    { title: 'Completions', dataIndex: 'completions', key: 'completions', sorter: (a, b) => a.completions - b.completions},
    { title: 'Checkins', dataIndex: 'checkins', key: 'checkins', sorter: (a, b) => a.checkins - b.checkins}
  ]

  return <Table columns={columns} dataSource={ripplesParsed} pagination={{position: ['bottomLeft'], defaultPageSize: 100}} />
}

function MembersTable({stream, memberships}) {
  const membersParsed = memberships?.map(member => {
    return { 
      key: member.id, 
      name: member.name || '',
      email: member.email || '',
      date: new Date(member.date), 
      dateNumeric: new Date(member.date).getTime(),
      ripplesViewed: member.ripplesViewed, 
      totalViews: member.totalViews, 
      ripplesCompleted: member.totalCompletions,
      checkins: member.checkins,
      ...(stream.memberInsightsQuestions || []).reduce(function(map, question) {
        let answer = member.memberInsightsAnswers?.find(a => a.questionId === question.id);
        let answerText = !!answer ? question.options.find(o => o.id === answer.answerId)?.text : ''; 

        map[question.id] = answerText || ''
        return map;
      }, {})
    }
  })


  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
    { title: 'Email', dataIndex: 'email', key: 'email', sorter: (a, b) => a.email.localeCompare(b.email) },
    { title: 'Date Joined', dataIndex: 'date', key: 'date', render: (text, record) => record.date.toLocaleDateString("en-NZ"), sorter: (a, b) => a.dateNumeric - b.dateNumeric },
    { title: 'Ripples Viewed', dataIndex: 'ripplesViewed', key: 'ripplesViewed', sorter: (a, b) => a.ripplesViewed - b.ripplesViewed },
    { title: 'Total Views', dataIndex: 'totalViews', key: 'totalViews', sorter: (a, b) => a.totalViews - b.totalViews},
    { title: 'Completions', dataIndex: 'ripplesCompleted', key: 'ripplesCompleted', sorter: (a, b) => a.ripplesCompleted - b.ripplesCompleted},
    { title: 'Checkins', dataIndex: 'checkins', key: 'checkins', sorter: (a, b) => a.checkins - b.checkins}
  ]

  for (const question of stream.memberInsightsQuestions || []) {
    columns.push({ title: question.question, dataIndex: question.id, key: question.id, sorter: (a, b) => a.name.localeCompare(b.name) })
  }

  return <div style={{overflowX: "auto"}}>
    <div style={{minWidth: columns.length * 150}}>
      <Table columns={columns} dataSource={membersParsed} pagination={{position: ['bottomLeft'], defaultPageSize: 100}} />
    </div>
  </div>
}

function Checkins({checkins}) {
  return <List
  grid={{gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 3}} 
  dataSource={checkins} 
  renderItem={checkIn => { return (
    <List.Item>
      <div>
        <div style={{color: 'grey', fontSize: 12}}>{new Date(checkIn.dateCreated).toLocaleDateString("en-NZ", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }).toString()}</div>
        <Card 
          cover={checkIn.storyImages && checkIn.storyImages.length > 0 && <div style={{borderBottom: '1px solid #f0f0f0', position: 'relative'}}>
            <Carousel autoplay>
              {checkIn.storyImages.map((img) => { return <div style={{textAlign: 'center'}}><img src={img.url} style={{maxWidth: '100%', maxHeight: 300, margin: 'auto'}} alt="" /></div>})}
            </Carousel>
          </div>}
        >
          <Card.Meta 
            title={<>
              {checkIn.rippleTitle ? <Title level={4}><div style={{fontSize: 14, lineHeight: '14px'}}>Ripple:</div>{checkIn.rippleTitle}</Title> : null}
              <span>{checkIn.name || 'Unknown Member'}</span><br/>
              {checkIn.shareStory ? <Tag color="success">Happy to share with the community</Tag> : <Tag color="warning">Prefer to keep private</Tag>}
            </>}
            description={<Paragraph ellipsis={{ rows: 3, expandable: true }} style={{whiteSpace: 'pre-wrap'}}>{checkIn.story}</Paragraph>} 
          />
        </Card>
      </div>
    </List.Item>
  )}} />
}