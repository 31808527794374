import React, { useContext, useCallback } from 'react';
import { AuthContext, SignOut as FirebaseSignOut } from '../../contexts/Auth';
import logo from '../../assets/make-ripples-medium.png';

import { Layout, Menu, Row, Col, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
const { Header, Content, Footer } = Layout;
const { Text } = Typography;

export default function Page({children, breadcrumb}) {
  const { user } = useContext(AuthContext);
  const SignOut = useCallback(async () => {
    await FirebaseSignOut();
  }, [])

  if (!user) {
    return <Row type="flex" justify="center" align="middle" style={{height: '100vh'}}><Col style={{width: '90%', maxWidth: '500px'}}>{children}</Col></Row>;
  }

  return <Layout>
    <Header style={{height: 'unset', lineHeight: '50px'}}>
      <Text style={{color: 'white', float: 'left'}}>Hi {user.displayName}</Text>
      <Menu theme="dark" mode="horizontal" selectable={false} style={{lineHeight: '50px'}}>
        <Menu.Item onClick={SignOut} style={{float: 'right'}}><LogoutOutlined /> Sign out</Menu.Item>
      </Menu>
    </Header>
    <Content style={{padding: breadcrumb ? '0 50px' : '50px'}}>
      {breadcrumb && <div style={{margin: '16px 0'}}>{breadcrumb}</div>}
      <div style={{background: '#fff', padding: 24, minHeight: 240}}>{children}</div>
    </Content>
    <Footer style={{ textAlign: 'center' }}><img src={logo} style={{height: 100}} alt="makeripples logo" /></Footer>
  </Layout>
}