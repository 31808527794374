import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import environment from 'environment';


if (!firebase.apps.length) {
  firebase.initializeApp(environment.firebaseConfig);
  
  firebase.firestore().settings({
    ignoreUndefinedProperties: true,
  })
  console.log(`Firebase Initialised - Project ID: ${environment.firebaseConfig.projectId}`);
}

firebase.app();

export default firebase;