import { Link } from 'react-router-dom';
import useOrganisations from 'hooks/firestore/useOrganisations';
import { List, Avatar, Tag, Typography, Button, Divider } from 'antd';
import Page from 'components/layout/Page';
import VerifyButton from 'components/VerifyButton';
import { GetImage, ImageSize } from 'services/images';
import environment from 'environment';
import DeleteOrganisationButton from 'components/DeleteOrganisationButton';

const { Title, Paragraph } = Typography;

export default function Dashboard() {
  const { loading, organisations } = useOrganisations();

  return <Page>
    <Link to="/promote">Create an admin</Link>
    <Divider />
    <Title level={3}>Organisations</Title>
    <List dataSource={organisations || []} loading={loading} renderItem={organisation => {
      const verify = organisation.verified ? <Tag color="success">Verified</Tag> : <VerifyButton organisationId={organisation.id} />;
      const impersonate = <a href={`${environment.appUrl}/admin/impersonate/${organisation.ownerId}`} target="_blank" rel="noopener noreferrer"><Button type="link">Impersonate</Button></a>;
      const deleteOrganisation = <DeleteOrganisationButton organisationId={organisation.id} organisationName={organisation.name} />;

      return <List.Item key={organisation.id} actions={[verify, impersonate, deleteOrganisation]}>
        <Link to={`/organisations/${organisation.id}`} style={{width: '100%'}}>
          <List.Item.Meta avatar={<Avatar size={100}  src={GetImage(organisation.image, ImageSize.small)} />} title={organisation.name} description={<Paragraph style={{fontSize: 12}}>{`Joined ${organisation.createTime?.toLocaleDateString("en-NZ") || ''}`}</Paragraph>}></List.Item.Meta>
        </Link>
      </List.Item>
    }} />
  </Page>
};