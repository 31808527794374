/* eslint-disable no-unused-vars */
import React from "react";

export const GlobalContext = React.createContext();
export const GlobalContextProvider = ({ children }) => {  
  return (
    <GlobalContext.Provider>
      {children}
    </GlobalContext.Provider>
  );
};