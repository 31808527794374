import React, { useState } from 'react';
import { Form, Input, Button, Divider, Modal, message, Alert } from 'antd';
import { SignIn as FirebaseSignIn, SignInWithGoogle as FirebaseSignInWithGoogle, ForgotPassword } from "../../contexts/Auth";
import { GoogleOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [signInError, setSignInError] = useState(null);
  const [form] = Form.useForm();

  const onFinish = async values => {
    setLoading(true);
    try {
      await FirebaseSignIn(values.email, values.password);
    } catch (error) {
      setSignInError(error.message);
      setLoading(false);
    }
  }

  const signInWithGoogle = async () => {
    await FirebaseSignInWithGoogle();
  }

  return <>
    <Form form={form} layout="vertical" onFinish={onFinish}>
      {signInError && <Form.Item><Alert message={signInError} type="error" /></Form.Item>}
      <Form.Item name="email" label="Email" rules={[{required: true, message: 'Please provide a valid email'}, {type: 'email', message: 'Please provide a valid email'}]}>
        <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
      </Form.Item>
      <Form.Item name="password" label="Password" rules={[{required: true, message: 'Please input your password'}]}>
        <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
      </Form.Item>
      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">Sign in</Button><br/>
        <Button type="link" onClick={() => {setShowForgotPassword(true);}} style={{paddingLeft: 0}}>I forgot my password</Button>
      </Form.Item>
      <Divider />
      <Button icon={<GoogleOutlined />} type="default" onClick={signInWithGoogle}>Sign in with Google</Button>
    </Form>
    {showForgotPassword && <ResetPasswordModal initialEmail={form.getFieldValue('email')} show={showForgotPassword} hide={() => setShowForgotPassword(false)} />}
  </>
}

function ResetPasswordModal({initialEmail, show, hide}) {
  const onFinish = values => {
    try {
      ForgotPassword(values.email);
      hide();
      message.success('Sending password reset email')
    } catch (error) {
      message.error('An unexpected error occurred');
      console.error(error);
    }
  }

  return (
    <Modal footer={null} title="Reset your password" visible={show} onCancel={hide}>
      <Form layout="vertical" onFinish={onFinish} initialValues={{email: initialEmail}}>
        <Form.Item name="email" label="Email" rules={[{required: true, message: 'Please provide a valid email'}, {type: 'email', message: 'Please provide a valid email'}]}>
          <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Reset my password</Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}