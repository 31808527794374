export enum ImageSize { placeholder, thumbnail, small, medium, large, full };
export interface Image {
  name: string,
  url: string,
  sizes?: {
    placeholder?: string,
    thumbnail?: string,
    small? : string,
    medium?: string,
    large?: string
  }
}


export function GetImage(image: Image, minimumSize: ImageSize) {
  if (!image) { return null; }
  if (!image.url && typeof image === 'string') { return image; } // Supports the old format
  if (!image.sizes || minimumSize === ImageSize.full) {
    return image.url;
  }

  switch(minimumSize) {
    case ImageSize.placeholder:
      return image.sizes.placeholder || image.sizes.thumbnail || image.sizes.small || image.sizes.medium || image.sizes.large || image.url;

    case ImageSize.thumbnail:
      return image.sizes.thumbnail || image.sizes.small || image.sizes.medium || image.sizes.large || image.url;

    case ImageSize.small:
      return image.sizes.small || image.sizes.medium || image.sizes.large || image.url;

    case ImageSize.medium:
      return image.sizes.medium || image.sizes.large || image.url;

    case ImageSize.large:
      return image.sizes.large || image.url;
  }

  return null;
}