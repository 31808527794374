import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Form, Input, Divider, Breadcrumb, message } from 'antd';
import Page from 'components/layout/Page';
import { MakeAdmin as ServiceMakeAdmin } from 'services/functions/users';

const { Title } = Typography;

export default function MakeAdmin() {
  const [loading, setLoading] = useState(false);

  const breadcrumb = <Breadcrumb>
    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
  </Breadcrumb>

  const onFinish = async (values) => {
    setLoading(true);
    if (values.email) {
      try {
        const result = await ServiceMakeAdmin(values.email);
        if (result.data.success) {
          message.success('User successfully set as an admin')
        } else {
          message.error(result.data.error || 'Failed to set user as an admin');
        }
      } catch (error) {
        message.error('An unexpected error occurred - Failed to set user as an admin');
      }
    }
    setLoading(false);
  }

  return <Page breadcrumb={breadcrumb}>
    <Title level={3} style={{margin: 0}}>Make an admin</Title>
    <Title level={4} type="secondary" style={{margin: 0}}>Someone's getting a promotion...</Title>
    <Divider />
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item name="email" label="Email" rules={[{required: true}, { type: 'email' }]}>
        <Input type="email"></Input>
      </Form.Item>
      <Form.Item>
        <Button loading={loading} shape="round" htmlType="submit" type="primary">Promote</Button>
      </Form.Item>
    </Form>
  </Page>
};