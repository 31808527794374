import React from "react";
import { Card, Row, Col } from 'antd';
import SignInForm from '../../components/forms/SignIn';
import logo from '../../assets/make-ripples.png';

export default function SignIn() { 
  return (
    <Row type="flex" justify="center" align="middle" style={{height: '100vh'}}>
      <Col style={{width: '90%', maxWidth: '500px'}}>
        <div style={{width: '100%'}}>
          <img alt="makeripples logo" src={logo} style={{padding: '1rem', width: '100%'}} />
          <Card style={{width: '100%', maxWidth: '800px'}}>
            <h1>Admin Sign in</h1>
            <SignInForm />
          </Card>
        </div>
      </Col>
    </Row>
  );
}