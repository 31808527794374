import React, { useState } from 'react';
import { Button, message, Modal, Typography } from 'antd';
import { DeleteOrganisation } from 'services/functions/organisations';

export default function DeleteOrganisationButton({organisationId, organisationName}) {
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isCertain, setIsCertain] = useState(false);

  const handleOk = async () => {
    if (isCertain) {
      setLoading(true);
      let result = { data: { success: false } };
      try {
        result = await DeleteOrganisation(organisationId);
      } catch (error) {
        console.error(error);
      }

      if (result.data.success) {
        message.success('Organisation deleted');
      } else {
        message.error(result.data.error || 'Sorry, something went wrong');
      }
      setLoading(false);
      close();
    } else {
      setIsCertain(true);
    }
  }

  const close = () => {
    setShowConfirmModal(false);
    setIsCertain(false);
    setLoading(false);
  }

  return <React.Fragment>
    <Modal title={`Delete ${organisationName}`} visible={showConfirmModal} onOk={handleOk} onCancel={close} okText={isCertain ? "Really Delete" : "Delete"} okButtonProps={{loading: loading}}>
      <Typography.Title level={4}>{isCertain ? "Are you really sure?" : "Are you sure?"}</Typography.Title>
      {!isCertain && <Typography.Paragraph>Deleting <b>{organisationName}</b> cannot be undone.</Typography.Paragraph>}
      {isCertain && <Typography.Paragraph>Once you do this there is no going back!</Typography.Paragraph>}
    </Modal>
    <Button size="small" danger loading={loading} onClick={() => { setShowConfirmModal(true) }}>Delete</Button>
  </React.Fragment>
}