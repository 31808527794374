import React, { useEffect } from 'react';
import { AuthProvider } from './contexts/Auth';
import { GlobalContextProvider } from './contexts/GlobalContext';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './styles/common.less'
import environment from 'environment';
import { notification } from 'antd';

// Routes
import Dashboard from 'pages/Dashboard';
import Organisation from 'pages/Organisation';
import Stream from 'pages/Stream';
import StreamReport from 'pages/StreamReport';
import Ripple from 'pages/Ripple';
import MakeAdmin from 'pages/MakeAdmin';

export default function App() {
  useEffect(() => {
    if (window.location.hostname === 'localhost' || !environment.isProduction) {
      notification.open({key: 'environment', placement: 'bottomRight', duration: 0, message: 'Environment', description: <span>Project: {environment.firebaseConfig.projectId}</span>})
    } 
  }, [])

  return (
    <AuthProvider>
      <GlobalContextProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/promote" component={MakeAdmin} />
            <Route path="/organisations/:organisationId/streams/:streamId/report" component={StreamReport} />
            <Route path="/organisations/:organisationId/streams/:streamId/ripples/:rippleId" component={Ripple} />
            <Route path="/organisations/:organisationId/streams/:streamId" component={Stream} />
            <Route path="/organisations/:organisationId" component={Organisation} />
          </Switch>
        </Router>
      </GlobalContextProvider>
    </AuthProvider>
  )
}