import React, { useEffect, useState } from "react";
import firebase from '../Firebase';
import firebaseAuth from 'firebase/app';
import { Button } from 'antd';
import SignInPage from 'pages/account/SignIn';

export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        let tokenResult = await currentUser.getIdTokenResult();
        setCurrentUser(currentUser);
        setIsAdmin(tokenResult.claims.admin);
        setLoading(false);
      } else {
        setCurrentUser(null);
        setIsAdmin(false);
        setLoading(false);
      }
    });
  }, []);

  const updateUser = async (displayName) => {
    await currentUser.updateProfile({displayName});
    forceUpdate();
  }

  let body = null;
  if (currentUser) {
    if (isAdmin) {
      body = children;
    } else {
      body = <p>Sorry, you are not an admin. <Button onClick={SignOut}>Sign Out</Button></p>
    }
  } else {
    body = <SignInPage />
  }

  return (
    <AuthContext.Provider value={{user: currentUser, updateUser}}>
      {loading ? null : body}
    </AuthContext.Provider>
  );
};

export async function SignIn(email, password) {
  await firebase.auth().signInWithEmailAndPassword(email, password);
}

export async function SignInWithGoogle() {
  let provider = new firebaseAuth.auth.GoogleAuthProvider();
  firebase.auth().signInWithRedirect(provider)
}

export async function ForgotPassword(email, continueUrl = 'https://organiser.makeripples.nz') {
  firebase.auth().sendPasswordResetEmail(email, { url: continueUrl});
}

export async function SignOut() {
  await firebase.auth().signOut();
}