import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useOrganisation from 'hooks/firestore/useOrganisation';
import useStream from 'hooks/firestore/useStream';
import { List, Avatar, Tag, Typography, Breadcrumb, Skeleton, Button, Form, Input, message, Divider } from 'antd';
import Page from 'components/layout/Page';
import { GetImage, ImageSize } from 'services/images';
import { AddStreamCollaborator } from 'services/functions/streams';

const { Title, Paragraph } = Typography;

export default function Stream({ location }) {
  let { organisationId, streamId } = useParams();
  const { organisation } = useOrganisation(organisationId);
  const { loading: streamLoading, stream } = useStream(organisationId, streamId);

  const breadcrumb = <Breadcrumb>
    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
    <Breadcrumb.Item><Link to={`/organisations/${organisation?.id}`}>{organisation?.name}</Link></Breadcrumb.Item>
  </Breadcrumb>

  return <Page breadcrumb={breadcrumb}>
    <Header organisationId={organisationId} stream={stream} />
    <Collaborators organisationId={organisationId} streamId={streamId} stream={stream} />
    <Divider />
    <Ripples organisationId={organisationId} streamId={streamId} ripples={stream?.ripples} loading={streamLoading} />
  </Page>
};

function Header({organisationId, stream}) {
  if (stream) {
    return <React.Fragment>
      <Title level={2} style={{marginBottom: 0}}>{stream?.name}<span style={{float:'right'}}><Link to={`/organisations/${organisationId}/streams/${stream?.id}/report`}><Button>Report</Button></Link></span></Title>
      <Paragraph>{`Created ${stream?.createTime?.toLocaleDateString("en-NZ")}`}</Paragraph>
    </React.Fragment>
  }

  return <React.Fragment>
    <Skeleton active title={true} paragraph={false} />
    <Skeleton active title={false} paragraph={{rows: 1, width: 150}} />
  </React.Fragment>
}

function Collaborators({organisationId, streamId, stream}) {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    if (values.organisationId) {
      try {
        const result = await AddStreamCollaborator(organisationId, streamId, values.organisationId);
        console.log(result);
        if (result.data.success) {
          message.success('Successfully added organisation as collaborator')
        } else {
          message.error('Failed to add organisation as collaborator');
        }
      } catch (error) {
        message.error('An unexpected error occurred - Failed to add organisation as collaborator');
      }
    }
    setLoading(false);
  }

  console.log(stream?.organisations)

  return <React.Fragment>
    <Title level={3}>Collaborators</Title>
    {(stream?.organisations || []).map(o => {
      if (o.id === organisationId) { return null }
      return <Paragraph key={o.id}>{o.name}</Paragraph>
    })}

    <Title level={3}>Add Collaborator</Title>
    <Form layout="inline" onFinish={onFinish}>
      <Form.Item name="organisationId" label="Collaborating Organisation ID" rules={[{required: true}]}>
        <Input type="organisationId"></Input>
      </Form.Item>
      <Form.Item>
        <Button loading={loading} shape="round" htmlType="submit" type="primary">Add</Button>
      </Form.Item>
    </Form>
  </React.Fragment>
}

function Ripples({organisationId, streamId, ripples, loading}) {
  return <React.Fragment>
    <Title level={3}>Ripples</Title>
    <List dataSource={ripples || []} loading={loading} renderItem={ripple => {
      return <List.Item key={ripple.id} extra={<Tag>{ripple.type.toUpperCase()}</Tag>}>
        <Link to={`/organisations/${organisationId}/streams/${streamId}/ripples/${ripple.id}`} style={{width: '100%'}}>
          <List.Item.Meta avatar={<Avatar size={100}  src={GetImage(ripple.coverImage, ImageSize.small)} />} title={ripple.title}></List.Item.Meta>
        </Link>
      </List.Item>
    }} />
  </React.Fragment>
}