import React, { useState } from 'react';
import { Button, message } from 'antd';
import { VerifyOrganisation } from 'services/functions/organisations';

export default function VerifyButton({organisationId}) {
  const [loading, setLoading] = useState(false);

  const Verify = async () => {
    setLoading(true);
    let result = await VerifyOrganisation(organisationId);
    if (result.data.success) {
      message.success('Organisation verified');
    } else {
      message.error(result.data.error || 'Sorry, something went wrong');
    }
  }

  return <Button size="small" loading={loading} onClick={Verify}>Verify</Button>
}