import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useOrganisation from 'hooks/firestore/useOrganisation';
import useStream from 'hooks/firestore/useStream';
import useRipple from 'hooks/firestore/useRipple';
import { Typography, Breadcrumb, Skeleton } from 'antd';
import Page from 'components/layout/Page';

const { Title } = Typography;

export default function Stream({ location }) {
  let { organisationId, streamId, rippleId } = useParams();
  const { organisation } = useOrganisation(organisationId);
  const { stream } = useStream(organisationId, streamId);
  const { ripple } = useRipple(organisationId, streamId, rippleId);

  const breadcrumb = <Breadcrumb>
    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
    <Breadcrumb.Item><Link to={`/organisations/${organisation?.id}`}>{organisation?.name}</Link></Breadcrumb.Item>
    <Breadcrumb.Item><Link to={`/organisations/${organisation?.id}/streams/${stream?.name}`}>{stream?.name}</Link></Breadcrumb.Item>
  </Breadcrumb>

  return <Page breadcrumb={breadcrumb}>
    <Header ripple={ripple} />
    <Body ripple={ripple}/>
  </Page>
};

function Header({ripple}) {
  if (ripple) {
    return <React.Fragment>
      <Title level={2} style={{marginBottom: 0}}>{ripple?.title}</Title>
    </React.Fragment>
  }

  return <React.Fragment>
    <Skeleton active title={true} paragraph={false} />
    <Skeleton active title={false} paragraph={{rows: 1, width: 150}} />
  </React.Fragment>
}

function Body({ripple}) {
  if (ripple) {
    return <React.Fragment>
      <div className="ripple-body" dangerouslySetInnerHTML={{__html: ripple.body}}></div>
    </React.Fragment>
  }

  return <Skeleton />
}